import { useMemo } from 'react';

import { useGetAvailableLanguages } from '../query/languages';
import { useGetCountries } from '../query/locations';
import { Role } from '../utils/enums';
import { useGetListCampaigns, useGetMyCampaigns } from '../query/campaigns';
import { CapacityData, ListCampaignsItem, MyCampaign } from '../api/campaigns.types';
import { GetByIdWithCacheFn } from './useCache';
import { colors } from '../utils/theme';
import { Country } from '../api/account';
import { RequestCategoriesType } from '../api/request.types';
import { useMainContext } from '../MainProvider';
import { isRoleInlcudesCM, isRoleIncludesLeader } from '../utils/profile';

export type PreparedFullCampaign = ListCampaignsItem & {
  role: Role;
  active: string | null;
  capacities: Record<string, CapacityData>,
  countryData: Array<{title: string | null, value: number}>;
  requestData: Array<{title: string | null, value: number}>;
};

const prepareCapacities = (
  capacities: Record<string, Record<string, number>>,
  getAvailableLanguageByLanguage: GetByIdWithCacheFn<Record<string, string>>,
) => {
  if (!capacities) {
    return {};
  }

  const results: Record<string, CapacityData> = {};
  let totalCount = 0;
  const capacityLength = Object.keys(capacities).length;

  const initialData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          colors.orangeLight,
          colors.green,
          colors.blue,
          colors.greenAqua,
          colors.redLight,
        ],
        spacing: 10,
      },
    ],
  };

  for (let i = 1; i <= capacityLength; i++) {
    let capacity = capacities[i];
    const cap: Array<{title: string, value: number}> = [];

    for (let key in capacity) {
      if (key === 'total') {
        totalCount = capacity[key];
        continue;
      }
      cap.push({ title: getAvailableLanguageByLanguage(key)?.name || key, value: capacity[key] });
    }

    cap.sort((a, b) => b.value - a.value);
    const topFive = cap.slice(0, 5);

    results[RequestCategoriesType[i]] = {
      ...initialData,
      labels: topFive.map(item => item.title),
      datasets: [
        ...initialData.datasets.map(d => ({ ... d, data: topFive.map(item => item.value) })),
      ],
      allData: { data: cap, totalCount },
    };
  }

  return results;
};

const prepareRequestData = (
  requestData: any,
  getAvailableLanguageByLanguage: GetByIdWithCacheFn<{ [key: string]: string }>,
) => {
  const transformedRequestData: { title: string, value: number }[] = [];
  const temp: { [key: string]: number } = {};

  for (let i = 0; i < requestData?.length; i++) {
    const item = requestData[i];

    temp[item.language] ? temp[item.language]++ : temp[item.language] = 1;
  }

  for (const key in temp) {
    transformedRequestData.push({
      title: getAvailableLanguageByLanguage(key)?.name || key,
      value: temp[key],
    });
  }

  transformedRequestData.sort((a, b) => b.value - a.value);

  return transformedRequestData;
};

const prepareCountryData = (
  countryData: { country: string | null, count: string }[],
  getCountryByAbbreviation: GetByIdWithCacheFn<Country>
) => {
  const data = countryData?.map((item) => {
    const country = getCountryByAbbreviation((item.country || '').toUpperCase());

    return {
      title: country?.country || item.country || 'Unknown',
      value: +item.count,
    };
  });

  data?.sort((a, b) => b.value - a.value);

  return data;
};

export const prepareFullCampaigns = (
  list: ListCampaignsItem[],
  userList: MyCampaign[],
  getAvailableLanguageByLanguage: GetByIdWithCacheFn<{ [key: string]: string }>,
  getCountryByAbbreviation: GetByIdWithCacheFn<Country>,
) => {
  const result = [] as PreparedFullCampaign[];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    const { capacities, countryData, requestData } = item || {};

    const userCampaign = userList.find((campItem) => campItem.campaignId === item.id);
    const transformedCapacities = prepareCapacities(capacities, getAvailableLanguageByLanguage);
    const transformedRequestData = prepareRequestData(requestData, getAvailableLanguageByLanguage);
    const transformedCountryData = prepareCountryData(countryData, getCountryByAbbreviation);

    const campaignItem = {
      ...item,
      active: userCampaign ? (userCampaign.active === '1').toString() : null,
      status: userCampaign ? item.status : null,
      capacities: transformedCapacities,
      countryData: transformedCountryData,
      requestData: transformedRequestData,
    } as unknown as PreparedFullCampaign;

    if (userCampaign) {
      campaignItem.role = userCampaign.role ? userCampaign.role : Role.DM;
    }

    result.push(campaignItem);
  }

  return result;
};

export const usePreparedFullCampaigns = () => {
  const { isUiRoleLeader, isUiRoleCM, isRoleAdmin } = useMainContext();
  const { myCampaigns, isMyCampaignsLoading } = useGetMyCampaigns();

  const myCampaignsIds = useMemo(() => {
    return myCampaigns.map((item) => item.campaignId);
  }, [myCampaigns]);

  const listCampaignsHook = useGetListCampaigns({
    all: true,
    extended: false,
    campaignIds: myCampaignsIds,
  }, {
    enabled: !!myCampaignsIds.length,
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  const listCampaigns = listCampaignsHook.data;

  const { getAvailableRequestLanguageByLanguage } = useGetAvailableLanguages();
  const { getCountryByAbbreviation } = useGetCountries();

  const preparedFullCampaigns = useMemo(() => {
    if (!listCampaigns) {
      return null;
    }

    return prepareFullCampaigns(
      listCampaigns,
      myCampaigns,
      getAvailableRequestLanguageByLanguage,
      getCountryByAbbreviation,
    );
  }, [listCampaigns, myCampaigns, getCountryByAbbreviation, getAvailableRequestLanguageByLanguage]);

  const preparedMyCampaigns = useMemo(() => {
    if (!preparedFullCampaigns) {
      return null;
    }

    return preparedFullCampaigns.filter((item) => {
      return (
        ((isRoleInlcudesCM(item.role) && isUiRoleCM) ||
          (isRoleIncludesLeader(item.role) && isUiRoleLeader)) &&
        item.exclude !== '1' &&
        item.startDate
      );
    });
  }, [preparedFullCampaigns, isUiRoleCM, isUiRoleLeader]);

  const preparedCampaigns = useMemo(() => {
    return isRoleAdmin ? preparedFullCampaigns : preparedMyCampaigns;
  }, [isRoleAdmin, preparedFullCampaigns, preparedMyCampaigns]);

  return {
    preparedFullCampaigns,
    preparedMyCampaigns,
    preparedCampaigns,
    isPreparedFullCampaignLoading: isMyCampaignsLoading || listCampaignsHook.isLoading,
    isListCampaignsFetching: listCampaignsHook.isFetching,
    refetchListCampaigns: listCampaignsHook.refetch,
    getListCampaignsItemById: listCampaignsHook.getItem,
  };
};
