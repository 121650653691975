import {
  CampaignsSummaryFromApi,
  DmRating,
  DmRatingFromApi,
  ListCampaignsItem,
  ListCampaignsItemConcise,
  ListCampaignsItemConciseApi,
  ListCampaignsItemFromApi, MyCampaign, MyCampaignApi,
  StatType, StatTypes
} from './campaigns.types';
import { capitalizeFirstLetter } from '../utils/string';
import { makeApiCampaignsSortedByStartDate } from '../utils/sorts';
import { ALL_TEAMS_CAMPAIGN_ID } from '../utils/campaigns';

const normalizeTeamStat = (
  stat: Record<string, number> | Array<Record<number, number>>,
  type: StatType,
  teamId: string,
) => {
  let statObj: Record<string, number> = {};

  if (Array.isArray(stat)) {
    if (stat.length) {
      statObj = { ...stat } as unknown as Record<string, number>;
    }
  } else {
    statObj = stat;
  }

  const result: ListCampaignsItem['stats'] = [];
  Object.keys(statObj).forEach((key) => {
    const day = parseInt(key, 10);
    result.push({
      id: `${teamId}_${type}_${day}`,
      teamId,
      type,
      day,
      count: statObj[key],
    });
  });
  return result;
};

const normalizeDmData = (dmData: DmRatingFromApi[]) => {
  const idsSet = new Set<string>();
  const result: DmRating[] = [];

  for (let i = 0; i < dmData?.length; i++) {
    let item = dmData[i];

    if (!item.firstname && !item.lastname && !item.chatScore && !item.responseTime && !item.score) {
      continue;
    }

    if (idsSet.has(item.id)) {
      continue;
    }

    result.push({
      ...item,
      chatScore: item.chatScore ? Number(item.chatScore).toFixed(1) : 0,
      responseTime: item.responseTime ? +item.responseTime : 0,
      score: item.score ? +item.score : 0,
      reportCount: item.reportCount ? +item.reportCount : 0,
    });
    idsSet.add(item.id);
  }

  return result;
};

const normalizeChatData = (chatData: Record<string, string> = {}) => {
  const result: Record<string, number> = {};
  for (let key in chatData) {
    result[key] = +chatData[key] || 0;
  }
  return result;
};

const chatDataToActivity = (chatData: Record<string, number> = {}) => {
  const result = [];

  for (let key in chatData) {
    const score = chatData[key];
    if (key === 'total' || !score) {
      continue;
    }
    result.push({ title: 'chatActivity' + capitalizeFirstLetter(key), score });
  }

  return result;
};

export const normalizeMyCampaign = (item: MyCampaignApi): MyCampaign => ({
  ...item,
  role: parseInt(item.role),
});

const normalizeCampaignItem = (item: ListCampaignsItemFromApi, totalCount?: number) => {
  const {
    requestCount = 0,
    unassignedCount = 0,
    openRequestCount = 0,
    closedRequestCount = 0,
    dmCount = 0,
    interestCount = 0,
    incomingMsgs = [],
    outgoingMsgs = [],
    interestsArr = [],
    dmsArr = [],
    capacities,
    chatData,
    countryCount = 0,
    countryData,
    incomingCount,
    requestResponseRate,
    dmData,
    requestData,
    unassignedData,
    interestIds,
  } = item.campaignStatistics || {};

  const normalizedChatData = normalizeChatData(chatData);

  const normalizedListCampaignsItem: ListCampaignsItem = {
    id: item.campaignId,
    name: item.name,
    description: item.description,
    status: item.campaignStatus,
    banner: item.banner,
    conference: item.conference,
    createDate: item.createDate,
    contactCode: item.contactCode,
    startDate: item.startDate,
    endDate: item.endDate,
    exclude: item.exclude,
    hidden: item.hidden,
    notifyEmail: item.notifyEmail,
    website: item.website,
    totalRequests: requestCount,
    waitingRequests: unassignedCount,
    totalDms: dmCount,
    totalInterests: interestCount,
    totalCountries: countryCount,
    openRequestCount,
    closedRequestCount,
    incomingCount,
    requestResponseRate,
    chatData: normalizedChatData,
    interestIds: interestIds || [],
    chatActivity: chatDataToActivity(normalizedChatData),
    dmData: normalizeDmData(dmData),
    capacities,
    countryData,
    requestData,
    unassignedData,
    stats: [],
  };

  if (totalCount !== undefined) {
    normalizedListCampaignsItem.totalCount = totalCount;
  }

  normalizedListCampaignsItem.stats.push(
    ...normalizeTeamStat(incomingMsgs, StatTypes.INCOMING, item.campaignId)
  );
  normalizedListCampaignsItem.stats.push(
    ...normalizeTeamStat(outgoingMsgs, StatTypes.OUTGOING, item.campaignId),
  );
  normalizedListCampaignsItem.stats.push(
    ...normalizeTeamStat(interestsArr, StatTypes.INTERESTS, item.campaignId),
  );
  normalizedListCampaignsItem.stats.push(
    ...normalizeTeamStat(dmsArr, StatTypes.DMS, item.campaignId),
  );

  return normalizedListCampaignsItem;
};

export const normalizeListCampaigns = (
  listCampaigns: ListCampaignsItemFromApi[],
  totalCount: number
) => {
  const result = [];

  for (let i = 0; i < listCampaigns.length; i++) {
    const currentItem = listCampaigns[i];
    result.push(normalizeCampaignItem(currentItem, i === 0 ? totalCount : undefined));
  }

  return result;
};

export const normalizeCampaignSummaryItem = (
  campaignsSummaryData: CampaignsSummaryFromApi,
  listCampaigns: ListCampaignsItemConcise[]
): ListCampaignsItem => {
  const sortedCampaigns = makeApiCampaignsSortedByStartDate(listCampaigns);
  const todayIsoStr = new Date().toISOString();
  const startDate = sortedCampaigns.length > 0 ? sortedCampaigns[0].startDate : todayIsoStr;

  const summaryItem: ListCampaignsItemFromApi = {
    name: 'allTeams',
    notifyEmail: null,
    hidden: null,
    createDate: startDate,
    startDate,
    endDate: todayIsoStr,
    description: null,
    exclude: null,
    conference: null,
    banner: null,
    website: '',
    campaignId: ALL_TEAMS_CAMPAIGN_ID,
    campaignStatistics: campaignsSummaryData,
    campaignStatus: 'running',
    contactCode: ''
  };
  
  return normalizeCampaignItem(summaryItem);
};

export const normalizeConciseCampaignItem = (
  item: ListCampaignsItemConciseApi
): ListCampaignsItemConcise => {
  return {
    id: item.campaignId,
    name: item.name,
    createDate: item.createDate,
    startDate: item.startDate,
    endDate: item.endDate,
    status: item.status,
    hidden: item.hidden,
    website: item.website,
  };
};
