import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalContext } from './modal/ModalProvider';
import { Typography } from './index';

const UserStatusPendingPopup: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Typography component='body3'>
      {t('yourStatusIs')}
      &nbsp;
      <strong>{t('pending')}.</strong>
      &nbsp;
      {t('contactAdmin')}
    </Typography>
  );
};

export const useUserStatusPendingPopup = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const openUserStatusPendingPopup = useCallback((onClose?: () => void) => {
    openModal(<UserStatusPendingPopup/>, {
      title: t('notice'),
      widthSize: 'sm',
      okButton: true,
      cancelButton: false,
      onClose: () => {
        if (onClose) {
          onClose();
        }
      }
    });
  }, [t]);

  return { openUserStatusPendingPopup };
};
