//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { isServiceWorkerSupported } from './utils/constants';
import { version } from '../package.json';

import './index.scss';

console.log('VERSION', version);

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (isServiceWorkerSupported) {
  navigator.serviceWorker.register('/ServiceWorker.js');
}
