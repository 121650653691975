//@ts-nocheck
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import { URLS } from '../utils/constants';
import { Header, Loader, SideMenu } from '../components';
import { useMainContext } from '../MainProvider';
import Page404 from './Page404';

import './MainLayout.scss';

const DashboardPage = React.lazy(() => import('./dashboard/DashboardPage'));
const ConnectPage = React.lazy(() => import('./connect/ConnectPage'));
const ConnectAdminPage = React.lazy(() => import('./connect/ConnectAdminPage'));
const AdminPage = React.lazy(() => import('./admin/AdminPage'));
const ApiPage = React.lazy(() => import('./admin/ApiPage'));
const TeamsPage = React.lazy(() => import('./teams/TeamsPage'));
const KnowledgePage = React.lazy(() => import('./knowledge/KnowledgePage'));
const CheckProfilesPage = React.lazy(() => import('./develop/CheckProfilesPage'));
const MyProfilePage = React.lazy(() => import('./information/MyInformationPage'));
const ProfilePage = React.lazy(() => import('./profile/ProfilePage'));
const SettingsPage = React.lazy(() => import('./settings/SettingsPage'));
const FaqPage = React.lazy(() => import('./settings/FaqPage'));
const StudentsPage = React.lazy(() => import('./students/StudentsPage'));

const MainLayout = React.memo(() => {
  const { isSideMenuSliding, isRoleAdmin } = useMainContext();

  return (
    <div className="layout-main">
      <SideMenu
        className={classNames('layout-main__menu', { 'has-sliding-menu': isSideMenuSliding })}
      />

      <div className="layout-main__content">
        <Header />
        <div className='layout-main__content__main'>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path={URLS.main} exact sensitive component={DashboardPage} />
              <Route path={URLS.connect} component={isRoleAdmin ? ConnectAdminPage : ConnectPage} />
              <Route path={URLS.admin} component={AdminPage} />
              <Route path={URLS.api} component={ApiPage} />
              <Route path={URLS.teams} component={TeamsPage} />
              <Route path={URLS.knowledge} component={KnowledgePage} />
              <Route path={URLS.myInformation} component={MyProfilePage} />
              <Route path={URLS.profile} component={ProfilePage} />
              <Route path={URLS.settings} component={SettingsPage} />
              <Route path={URLS.FAQ} component={FaqPage} />
              <Route path={URLS.students + '/:studentId?'} component={StudentsPage} />

              <Route path={URLS.checkProfiles} component={CheckProfilesPage} />

              {/* Fixes '/login' route and 404 are presented after sign in. */}
              <Route path={URLS.login}><Redirect to={URLS.main} /></Route>
              <Route path={URLS.register}><Redirect to={URLS.main} /></Route>

              <Route component={Page404} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>
  );
});

export default MainLayout;
